'use client'

import React, { useState, useEffect, useRef } from 'react'
import { motion, useAnimation } from "framer-motion"
import { ChevronRight, X, Database, FileText, Share2, Code } from 'lucide-react'

export default function Component() {
  const [activeView, setActiveView] = useState('Find')
  const [enlargedImage, setEnlargedImage] = useState('')
  const controls = useAnimation()
  const containerRef = useRef(null)

  const partners = [
    {
      name: "Hugging Face",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/telegram-cloud-photo-size-5-6125420901324406351-x-rnqh0OV87f33KCVPHoE64p38DgmXBo.jpg"
    },
    {
      name: "Ollama",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/telegram-cloud-photo-size-5-6125420901324406352-x-Zb4zdTstHZxO3zbkjGjH3v8kD6UCqa.jpg"
    },
    {
      name: "Unsloth",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/150920049%20(1)-JyOVfawHGgQZVUsuxK0iGmnhGVkk8a.png"
    },
    {
      name: "VLLM",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/1-FvTdokOC9uyatU0uwERs9Uzi9NMti9.png"
    },
    {
      name: "MLX",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/telegram-cloud-photo-size-5-6125420901324406359-m-dUPO627bUR5bfdCJAp0mpQA7aRwQSW.jpg"
    },
    {
      name: "GPT4ALL",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Pdoc-jXAPmBuMC1Vfc5beyHCfDvak9GJNzh.png"
    },
    {
      name: "TRL",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/3-x0LZkq7PDfyQNwZvSx5YnG5LifRiXV.png"
    },
    {
      name: "Modal",
      logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSm1lBtF19wO60RqDAql5tgZlV7zAbP1Y97Ug&s"
    },
    {
      name: "Google Colab",
      logo: "https://colab.research.google.com/img/colab_favicon_256px.png"
    },
    {
      name: "Axolotl",
      logo: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/2oHO2949q2KLC24tYTTUUQCGM7K-XQfearQUXNP12JFwD7Q3S6HGTD8LVm.svg"
    }
  ]

  useEffect(() => {
    const animateCarousel = async () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const contentWidth = partners.length * 320 // Assuming each partner logo takes 320px width

        await controls.start({
          x: [-contentWidth, 0],
          transition: {
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 30,
              ease: "linear",
            },
          },
        })
      }
    }

    animateCarousel()
  }, [controls, partners.length])

  const viewImages = {
    Find: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-11-03%20at%209.42.28%20PM-pYuyJaI5BLssncMZPQsOXL5Hx1kfq8.png", description: "Select from curated datasets or bring your own 📁 "},
    Tune: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-11-03%20at%2010.28.27%20PM-vOPJbVpu4G0kfAI2PE5lbqPLdp8lu3.png", description: "Locally or in the cloud, we've got you covered 🌍"},
    Evaluate: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-11-08%20at%201.50.46%20AM-kuhsCpoBTSTi3waIxNQTNkkmmeuyQP.png", description: "Fully integrated evaluation suite powered by Generative AI 🤖"},
    Deploy: {image: "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-11-03%20at%2010.43.21%20PM-tU4hdsrdCHflVUVePYRlUuRvOmeRpA.png", description: "Bring your models wherever you go 🧳, however you want"},
    
  }

  return (
    <div className="min-h-screen bg-white text-blue-900">
      <header className="bg-white shadow-sm py-4 sticky top-0 z-10">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <span className="text-3xl font-bold text-blue-600">Tune</span>
            </div>
            <nav className="hidden md:flex items-center space-x-8">
              <a href="#features" className="text-lg font-bold text-blue-600 hover:text-blue-700 transition-colors">Features</a>
              <a href="#integrations" className="text-lg font-bold text-blue-600 hover:text-blue-700 transition-colors">Integrations</a>
              <button 
                onClick={() => window.location.href = 'https://noteforms.com/forms/tune-waitlist-rum4ca'} 
                className="px-4 py-2 bg-blue-600 text-white text-sm font-bold rounded-md hover:bg-blue-700 transition-colors flex items-center"
              >
                Join Our Waitlist
                <ChevronRight className="ml-1 h-4 w-4" />
              </button>
            </nav>
          </div>
        </div>
      </header>

      <main>
        <section className="bg-blue-100 py-24 md:py-32">
          <div className="container mx-auto px-4">
            <div className="max-w-2xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-8 leading-tight text-blue-900">
                Find, Tune, Deploy.
              </h1>

              <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                <button 
                  onClick={() => window.location.href = '/login'} 
                  className="px-8 py-3 bg-blue-600 text-white text-lg font-bold rounded-md hover:bg-blue-700 transition-colors flex items-center justify-center"
                >
                  Join Our Waitlist
                  <ChevronRight className="ml-2 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16 md:py-24">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto">
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-center text-blue-900">
                Intuitive finetuning,deployment and evaluation process powered by AI
              </h2>
              <p className="text-lg text-center mb-8 text-blue-700">
                Experience the power of AI model fine-tuning with our user-friendly platform
              </p>
              <div className="flex justify-center space-x-4 mb-8">
                {['Find', 'Tune', 'Deploy', 'Evaluate'].map((view) => (
                  <button
                    key={view}
                    onClick={() => setActiveView(view)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                      activeView === view
                        ? 'bg-blue-200 text-blue-700'
                        : 'bg-blue-100 text-blue-600 hover:bg-blue-200'
                    }`}
                  >
                    {view.charAt(0) + view.slice(1)}
                  </button>
                ))}
              </div>
              <div className="bg-blue-100 rounded-lg shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105 cursor-pointer">
                <div
                  onClick={() => setEnlargedImage(viewImages[activeView].image)}
                  className="relative overflow-hidden"
                >
                  <img
                    src={viewImages[activeView].image}
                    alt={`${activeView} screenshot`}
                    className="w-full h-auto transition-transform duration-300 hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-0 hover:bg-opacity-10 transition-opacity duration-300"></div>
                </div>
                <div className="p-4 bg-blue-200 bg-opacity-50">
                  <p className="text-lg font-semibold mb-2 text-blue-800 text-center">
                    {viewImages[activeView].description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 md:py-24 bg-blue-100" id="features">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-900">Powerful Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-12">
              <div className="flex flex-col items-center">
                <div className="bg-blue-200 p-4 rounded-full mb-4">
                  <Database className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-800">Hassle-free model configuration</h3>
                <p className="text-sm text-blue-700 text-center">
                  Our algorithm automatically selects the best models and proposes configurations for your fine-tuning task.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-200 p-4 rounded-full mb-4">
                  <FileText className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-800">Plug and Play Data</h3>
                <p className="text-sm text-blue-700 text-center">
                  Upload your own data or port it over from Hugging Face. Power your in-house agents and language models with your own data.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-200 p-4 rounded-full mb-4">
                  <Code className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-800">Train anywhere, anyway</h3>
                <p className="text-sm text-blue-700 text-center">
                Train wherever you prefer—cloud or local, using popular frameworks for maximum flexibility
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="bg-blue-200 p-4 rounded-full mb-4">
                  <Share2 className="h-8 w-8 text-blue-600" />
                </div>
                <h3 className="text-lg font-semibold mb-2 text-blue-800">Bring Your Model Anywhere</h3>
                <p className="text-sm text-blue-700 text-center">
                Seamless Deployment—Your Model, Your Way! Easily export trained models to any platform, ensuring flexibility across deployments.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 md:py-24 bg-gradient-to-b from-blue-50 to-white" id="integrations">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center text-blue-900">
              Supported Frameworks and Integrations
            </h2>
            <div className="relative overflow-hidden w-full" style={{ height: '240px' }} ref={containerRef}>
              <motion.div
                className="flex absolute"
                animate={controls}
                style={{
                  width: `${partners.length * 320 * 2}px`,
                }}
              >
                {[...partners, ...partners].map((partner, index) => (
                  <div
                    key={`${partner.name}-${index}`}
                    className="flex flex-col items-center justify-center flex-shrink-0"
                    style={{ width: '320px' }}
                  >
                    <div className={`w-40 h-40 rounded-full shadow-md flex items-center justify-center p-6 mb-4 ${
                      partner.name === 'Modal' || partner.name === 'TRL' || partner.name === 'Axolotl' ? 'bg-black' : 'bg-white'
                    }`}>
                      <img
                        src={partner.logo}
                        alt={`${partner.name} logo`}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <span className="text-sm font-medium text-blue-700">{partner.name}</span>
                  </div>
                ))}
              </motion.div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-blue-100 text-blue-900 py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-2xl font-bold mb-4">Tune</h3>
              <p className="text-blue-700">
                Finetuning made easy.
                <br />
                Powering the next generation of AI agents and Language Models.
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-blue-600 hover:text-blue-700">Home</a></li>
                <li><a href="#features" className="text-blue-600 hover:text-blue-700">Features</a></li>
                <li><a href="#integrations" className="text-blue-600 hover:text-blue-700">Integrations</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Resources</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-blue-600 hover:text-blue-700">Documentation</a></li>
                <li><a href="#" className="text-blue-600 hover:text-blue-700">API Reference</a></li>
                <li><a href="#" className="text-blue-600 hover:text-blue-700">Blog</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Contact</h4>
              <p className="text-blue-700">
                Email: support@tune.ai
                <br />
                Phone: (123) 456-7890
              </p>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-blue-200 text-center text-blue-700">
            <p>© 2024 Tune AI. All rights reserved. A Typeless Production</p>
          </div>
        </div>
      </footer>

      {enlargedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative max-w-[90vw] max-h-[90vh] w-full h-full">
            <button 
              onClick={() => setEnlargedImage('')}
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
              aria-label="Close enlarged image"
            >
              <X className="h-8 w-8" />
            </button>
            <img
              src={enlargedImage}
              alt="Enlarged view"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      )}
    </div>
  )
}
